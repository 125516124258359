<template lang="pug">
  NioApiError(
    :error="error"
    @close="$router.back()"
  ) 

</template>

<script>
import NioApiError from '@narrative.io/tackle-box/src/components-private/ApiError'
export default {
  components: {
    NioApiError,
  },
  props: {
    error: {
      type: String,
      default: 'Unknown Error'
    }
  },
}
</script>
